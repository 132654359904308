import React from "react";
import {EntityCustomView, PropertyFieldBinding} from "@firecms/core";
import {Container} from "@firecms/ui";

export const chatbotPromptView: EntityCustomView = {
  key: "prompt_editor",
  name: "Prompt Editor",
  includeActions: true,
  Builder: ({
              collection,
              entity,
              modifiedValues,
              formContext
            }) => (
    <Container className={"my-16 flex flex-col gap-4"}>
      <PropertyFieldBinding context={formContext}
                            property={{
                              dataType: "string",
                              name: "Prompt",
                              multiline: true
                            }}
                            propertyKey={"prompt"}/>
      <PropertyFieldBinding context={formContext}
                            property={{
                              dataType: "string",
                              name: "Restrictions",
                              multiline: true
                            }}
                            propertyKey={"restrictions"}/>

    </Container>
  )
};